import React from 'react'
import { Container } from 'react-bootstrap'

const Card = ({title, color, text, firstSubText, secondSubText, imageClass , imageTitle, children}) => {
  return (
      <Container className="card" style={{backgroundColor: color}}>
        <h3 className="card-header-text">{title}</h3>
        <div className="card-paragraph-text">{text}</div>
        { firstSubText ? <div className="card-subtext">{firstSubText}<br></br>{secondSubText}</div> : null }
        <div className={imageClass} title={imageTitle} />
        { children }
      </Container>
  )
}

export default Card
