import React from 'react'
import { Form, Container, Row } from 'react-bootstrap'

const EmailInput = ({ width, onTextChange, label, onInputFocus }) => (
  <Container>
    <Row>
      <Form.Control 
        type="email"
        placeholder="Your email"
        className="email-input"
        required style={{ width: width }}
        onChange={onTextChange}
        onFocus={onInputFocus}
      />
    </Row>
    <Row>
      <div className="email-input-label" dangerouslySetInnerHTML={{__html: label}} />
    </Row>
  </Container>
)

export default EmailInput
