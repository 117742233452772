import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import Card from '../Card/card'
import CardCase from '../CardCase/cardCase'

const SecondLayout = () => {
  return (
    <>
      <Container className="main-second-layout-container">
        <Container className="second-layout-container">
          <Row>
            <Col lg="6">
              <h2 className="why-paragraph">Why NagNag</h2>
              <Card
                title="Delegate tasks"
                color="#DFEBF5"
                text="Assign tasks and get instantly notified of their progress."
                imageClass="second-card-image"
                imageTitle="delegate tasks app"
              />
              <Card
                title="Simplify and save time"
                color="#F6DDDF"
                text="A single intuitive tasks dashboard with all updates and zero complexity."
                imageClass="third-card-image"
                imageTitle="simple project management software"
              />
            </Col>
            <Col lg="6">
              <Card
                title="Organize work"
                color="#F6F7FB"
                text="Break down the rule with actionable tasks. Save time, avoid losing work and information. Track tasks to stay on schedule."
                imageClass="first-card-image"
                imageTitle="elegate tasks app"
              />
              <Card
                title="Optimize execution"
                color="#FEF3D8"
                text="Automated SMS notifications resulting in  faster and higher-rate task execution."
                imageClass="fourth-card-image"
                imageTitle="project tracking system"
                firstSubText={"*98% of texts are read vs. 20% email."}
                secondSubText={"**Most of texts messages are read within 3 minutes."}
              />
            </Col>
          </Row >
        </Container>
          <h2 className="use-case-text">Use Cases</h2>
          <Container>
          <Row>
            <Col lg="6">
              <CardCase
                  imageClass="first-card-case-image"
                  title="Personal"
                  color="#CCE6F7"
                  textClass="first-card-case-text"
                  firstText="Whether you're delegating chores among loved ones, working on a side project with friends, or planning a trip
                  NagNag helps you stay organized and on track."
                  secondText="Organize your personal network's daily task, set due dates and let NagNag automatically make sure the tasks
                  get done on time and with higher efficiency."
                  imageTitle="personal task management app"
                />
            </Col>
            <Col lg="6">
              <CardCase
                  imageClass="second-card-case-image"
                  title="Professional"
                  color="#FAD87C"
                  textClass="second-card-case-text"
                  firstText="Save time, delegate, track tasks and hit deadlines with a direct line of communication using SMS messages for task notifications -
                  empowering even those that are not tech-savvy."
                  secondText="Task dashboard gives you a clear overview of what's going on in your organization. You can see the tasks that are overdue, the
                  work that's due soon, see the overall performance and much more."
                  imageTitle="professional task tracker"
                />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="main-second-layout-container-mobile">
        <Container className="second-layout-container">
          <Row>
            <Col>
              <h2 className="why-paragraph">Why NagNag</h2>
            </Col>
          </Row>
          <Row>
            <Card
              title="Organize work"
              color="#F6F7FB"
              text="Break down the rule with actionable tasks. Save time, avoid losing work and information. Track tasks to stay on schedule."
              imageClass="first-card-image"
              imageTitle="elegate tasks app"
            />
          </Row>
          <Row>
            <Card
              title="Delegate tasks"
              color="#DFEBF5"
              text="Assign tasks and get instantly notified of their progress."
              imageClass="second-card-image"
              imageTitle="delegate tasks app"
            />
          </Row>
          <Row>
            <Card
              title="Optimize execution"
              color="#FEF3D8"
              text="Automated SMS notifications resulting in  faster and higher-rate task execution."
              imageClass="fourth-card-image"
              imageTitle="project tracking system"
            >
              <p className="card-bottom-subtext">*98% of texts are read vs. 20% email.<br></br>**Most of texts messages are read within 3 minutes.</p>
            </Card>
          </Row>
          <Row>
            <Card
              title="Simplify and save time"
              color="#F6DDDF"
              text="A single intuitive tasks dashboard with all updates and zero complexity."
              imageClass="third-card-image"
              imageTitle="simple project management software"
            />
          </Row>
        </Container>
        <Container className="main-use-case-mobile">
          <Row>
            <Container className="use-case-container">
              <h2 className="use-case-text">Use Cases</h2>
            </Container>
          </Row>
          <Row>
              <CardCase
                  imageClass="first-card-case-image"
                  title="Personal"
                  color="#CCE6F7"
                  textClass="first-card-case-text"
                  firstText="Whether you're delegating chores among loved ones, working on a side project with friends, or planning a trip
                  NagNag helps you stay organized and on track."
                  secondText="Organize your personal network's daily task, set due dates and let NagNag automatically make sure the tasks
                  get done on time and with higher efficiency."
                  imageTitle="personal task management app"
                />
          </Row>
          <Row>
              <CardCase
                  imageClass="second-card-case-image"
                  title="Professional"
                  color="#FAD87C"
                  textClass="second-card-case-text"
                  firstText="Save time, delegate, track tasks and hit deadlines with a direct line of communication using SMS messages for task notifications -
                  empowering even those that are not tech-savvy."
                  secondText="Task dashboard gives you a clear overview of what's going on in your organization. You can see the tasks that are overdue, the
                  work that's due soon, see the overall performance and much more."
                  imageTitle="professional task tracker"
                />
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default SecondLayout
