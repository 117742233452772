import React from 'react'
import { Container } from 'react-bootstrap'

const CardCase = ({title, color, textClass, firstText, secondText, imageClass, imageTitle}) => {
  return (
      <Container className="card-case" style={{backgroundColor: color}}>
        <div className={imageClass}  title={imageTitle} />
        <Container className="card-case-paragraph-container">
          <h3 className="card-case-header">{title}</h3>
          <p className={textClass}>{firstText} <br/><br/> {secondText}</p>
        </Container>
      </Container>
  )
}

export default CardCase
