import React, { useState } from 'react'
import { Link, navigate } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import EmailInput from '../EmailInput/emailInput'
import Button from '../Button/button'
import { LOWER_BUTTON, lwLinkClicked, joinWaitListClicked } from '../Amplitude/events'

const Footer = () => {

  const [ email, setEmail ] = useState('')
  const [errorMessage, setErrorMessage] = useState('') 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await addToMailchimp(email);
    joinWaitListClicked(LOWER_BUTTON, email);

    if (result.result === "error"){
      setErrorMessage(result.msg);
      return;
    }
    navigate('/success');
  }

  return (
  <>
    <Container fluid className="main-footer-container">
      <Container className="footer-container">
        <div className="footer-header-container"></div>
        <h2 className="footer-header">Empower your network to get <br></br> tasks done faster.</h2>
        <Container className="form-container">
          <Row className="container-row">
            <Col className="email-col">
                <EmailInput 
                  onTextChange={(event) => {setEmail(event.target.value)}}
                  label={errorMessage}
                  onInputFocus={() => setErrorMessage('')}
                />
            </Col>
            <Col>
              <Container className="button-col">
                <Button height="55px" width="200px" onMouseDown={handleSubmit}/>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="paragraph-container">
          <p className="footer-paragraph">*by joining our waitlist you're accepting the fact that we will send you<br></br> 
            emails regarding NagNag development
          </p>
        </Container>
      </Container>
      <Container fluid className="bottom-container">
        <Row>
          <Col >
            <p className="rights-text">NagNag all rights reserved</p>
          </Col>
          <Col>
            <p className="made-by-text">Proudly made by <a href="https://www.lambdaworks.io/" onMouseDown={lwLinkClicked} className="lambdaWorks-link"><b>LambdaWorks</b></a></p>
          </Col>
          <Col md="2">
            <Link to="/terms" className="terms-text">Terms of Service</Link> 
          </Col>
          <Col md="2">
            <Link to="/policy" className="policy-text">Privacy Policy</Link> 
          </Col>
        </Row>
      </Container>
    </Container>
    <Container fluid className="main-footer-container-mobile">
        <div className="footer-header-container"></div>
        <h2 className="footer-header">Empower your network <br></br> to get tasks done faster.</h2>
        <Container className="form-container">
            <Row className="mobile-row">
              <Container className="email-col">
                <EmailInput
                   width="100%"
                   label={errorMessage}
                />
              </Container>
            </Row>
            <Row>
              <Container className="button-col">
                <Button height="55px" onMouseDown={handleSubmit} />
              </Container>
            </Row>
            <Row>
            <p className="footer-paragraph">*by joining our waitlist you're accepting the fact that we will send you
              emails regarding NagNag development
            </p>
          </Row>
          <p className="made-by-text">Proudly made by <a href="https://www.lambdaworks.io/" onMouseDown={lwLinkClicked} className="lambdaWorks-link"><b>LambdaWorks</b></a></p>
          </Container>
        <Container fluid className="bottom-footer">
          <Container className="links-container">
          <Row style={{flex:1}}>
              <Col style={{textAlign:"right"}}>
                <Link to="/terms" className="mobile-terms-text">Terms of Service</Link> 
              </Col>
              <Col xs={1} style={{marginTop:10, paddingLeft:0, paddingRight:0}}>
                <span style={{fontSize:25}}>&#8739;</span>
              </Col>
              <Col style={{textAlign:"left"}}>
                <Link to="/policy" className="mobile-policy-text">Privacy Policy</Link> 
              </Col>
            </Row>
              <p className="rights-text">NagNag all rights reserved</p>
          </Container>
        </Container>
    </Container>
  </>
  )
}

export default Footer
