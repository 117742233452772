import React from 'react'
import { Link } from "gatsby"
import { Container } from 'react-bootstrap'
import Button from '../Button/button'
import Logo from '../../images/logo.svg'
import SEO from '../seo'
import MobileFrame from '../mobileFrame'
import WebFrame from '../webFrame'
import { UPPER_BUTTON, joinWaitListClicked } from '../Amplitude/events'
 
const FirstLayer = () => {
 
  const scrollToFooter = (event) => {
    joinWaitListClicked(UPPER_BUTTON);

    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    if(supportsNativeSmoothScroll) {
      window.scroll({
        top: document.body.scrollHeight,
        behavior:'smooth'
      });
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
 
  return (
    <>
      <Container fluid className="main-first-section-container">
        <Container className="first-section-container">
          <Link to="/"><Logo className="logo" /></Link>
          <Container className="main-header-container">
            <h1 className="header">Transform your network<br />into a dedicated task force</h1>
          </Container>
          <p className="paragraph">NagNag is a task management platform that leverages the power of<br/> <b>SMS messaging</b> to ensure reliable and hassle-free execution.</p>
          <Container className="button" >
            <Button onMouseDown={scrollToFooter} height="45px"/>
          </Container>
          <Container className="image-container" title="simple task management software" >
            <Container className="web-frame-container">
              <WebFrame />
            </Container>
            <Container className="mobile-frame-container">
              <MobileFrame />
            </Container>
          </Container>
        </Container>
      </Container>
      <Container fluid className="main-first-section-container-mobile">
        <SEO title="NagNag" />
        <Logo className="logo" />
        <Container className="main-header-container">
          <h1 className="header-mobile">Transform your network<br />into a dedicated task force</h1>
        </Container>
        <p className="paragraph-mobile">NagNag is a task management platform that leverages the power of <b>SMS messaging</b> to ensure reliable and hassle-free execution.</p>
        <Container className="button-mobile" >
          <Button onMouseDown={scrollToFooter} height="46px" width="160px"/>
          <div className="button-div"></div>
        </Container>
      </Container>
      <Container className="image-container-mobile" title="simple task management software" >
        <Container className="web-frame-container">
          <WebFrame />
        </Container>
        <Container className="mobile-frame-container">
          <MobileFrame />
        </Container>
      </Container>
      </>
  )
}
 
export default FirstLayer;
