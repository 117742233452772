import React from 'react'
import FirstSection from '../components/FirstSection/index'
import SecondSection from '../components/SecondSection/index'
import Layout from '../components/Layout/layout'
import Footer from '../components/Footer/footer'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO image="../images/OGimage.png" title="NagNag"></SEO>
    <FirstSection />
    <SecondSection />
    <Footer />
  </Layout>
)

export default IndexPage;
